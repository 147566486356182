import React from 'react';
import '../tailwind.css';
import '../stitches.css';

const Footer = () => {
    return (
        // <div className="font-sans bg-white py-8 px-4">
        //     <div
        //         className="mx-auto container overflow-hidden flex flex-col md:flex-row justify-between pb-4 text-center md:text-left">
        //         <a href="#" className="block mr-4 w-full md:w-1/4">
        //             <img
        //                 src="https://stitches-cdn.hyperyolo.com/logo.png"
        //                 className="w-32 -ml-8 md:ml-0"
        //                 alt="logo"/>
        //         </a>
        //         <div className="w-full text-grey-darkest mt-6 md:mt-0 leading-normal">
        //             <h4 className="py-2 uppercase font-bold tracking-wide">About US</h4>
        //             <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
        //                 Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
        //                 when an unknown printer took a galley of type and scrambled it to make a type
        //                 specimen book. It has survived not only five centuries, but also the leap into
        //                 electronic typesetting, remaining essentially unchanged. It was popularised in
        //                 the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
        //                 and more recently with desktop publishing software like Aldus PageMaker
        //                 including versions of Lorem Ipsum.</p>
        //         </div>
        
        //     </div>
        //     <div
        //         className="pt-4 mt-4 text-grey-darker text-xs border-t border-grey-lighter text-center">
        //         ©2019 TaRGET. All rights reserved.</div>
        // </div>
        <div className="font-sans bg-grey-darkest text-white py-8 px-4">
        <div className="mx-auto max-w-xl overflow-hidden flex justify-between items-center">
          <ul className="text-sm text-grey-dark list-reset flex items-center">
            <div className="block mr-4 w-32">
            <div className='font-bold tracking-wide text-grey-lightest font-sans text-xl font-extrabold'>
                TaRGET
            </div>
          </div>
            {/* <li><a href="#" className="inline-block py-2 px-3 text-grey hover:text-grey-light no-underline">Product</a></li>
            <li><a href="#" className="inline-block py-2 px-3 text-grey hover:text-grey-light no-underline">Company</a></li>
            <li><a href="#" className="inline-block py-2 px-3 text-grey hover:text-grey-light no-underline">FAQs</a></li> */}
            <li><a href="/about" className="inline-block py-2 px-3 text-grey hover:text-grey-light no-underline">About Us</a></li>
          </ul>
          <p className="inline-block py-2 px-3 text-grey-darker text-xs"> ©2019 TaRGET DCC. All rights reserved.</p>
          <div
                    className="text-grey-dark flex flex-col items-center w-full md:w-1/4 mt-6 md:mt-0">
                    <h4
                        className="inline-block py-2 px-3 uppercase font-bold tracking-wide text-grey-lightest">Follow Us</h4>
                    <div className="flex pl-0 md:pl-4 justify-start">
                        <a
                            className="block flex items-center text-grey-lightest hover:text-white mr-6 no-underline"
                            href="https://targetdcc.slack.com">
                            <img src="/icons8-slack.svg" alt=""/>
                        </a>
                        {/* <a
                            className="block flex items-center text-grey-lightest hover:text-white mr-6 no-underline"
                            href="#">
                            <i className="fab fa-slack"></i>
                        </a>
                        <a
                            className="block flex items-center text-grey-lightest hover:text-white no-underline"
                            href="#">
                            <i className="fab fa-twitter"></i>
                        </a> */}
                    </div>
                </div>
        </div>
    </div>
    );
}

export default Footer;